import './assets/blk-loading.css';
import './assets/global.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-quill/dist/quill.snow.css';

import React, { useEffect, useRef, useState } from 'react';

import { ConfigProvider, notification } from 'antd';

import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import { muiTheme } from 'assets/theme';
import Loader from 'components/Shared/Loader';
import Routes from 'Routes';
import { setup } from 'rxjs-operators';
import StyledHoustonTheme from 'styles';
import { antdTheme } from 'styles/antd';

import GoogleMapsLib from './services/GoogleMapsLib';

declare module '@mui/styles/defaultTheme' {
  /* eslint-disable-next-line @typescript-eslint/no-empty-object-type */
  interface DefaultTheme extends Theme {}
}

window['IS_CREATE_EVENT'] = false;

const App = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const loader = useRef(null);
  notification.config({
    placement: 'topRight',
    pauseOnHover: true,
    duration: 6
  });

  useEffect(() => {
    if (firstLoad) {
      setup(loader.current);
      setFirstLoad(false);
    }
  }, [firstLoad, loader]);

  console.log('(Front) Version Number: 84 / Branch: master / Commit: bc314a2275');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <ConfigProvider theme={antdTheme}>
          <StyledHoustonTheme>
            <>
              <GoogleMapsLib />
              <Loader innerRef={loader} />
              <Routes />
            </>
          </StyledHoustonTheme>
        </ConfigProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
