import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal } from 'antd';

import Grid from '@mui/material/Grid';

import Form from '../components/Form';
import StepButtonsGroup from '../components/StepButtonsGroup';
import FormContext, { FormContextProvider } from '../Context';
import * as S from './styles';

const CreateEventContent = () => {
  const { isSavingEvent } = useContext(FormContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextPath, setNextPath] = useState<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    if (isSavingEvent) {
      history.block(() => {})();
      return;
    }

    const unblock = history.block(nextLocation => {
      if (!isModalOpen) {
        setNextPath(nextLocation.pathname);
        setIsModalOpen(true);
      }

      return false;
    });

    return () => unblock();
  }, [history, isModalOpen, isSavingEvent]);

  const handleConfirmNavigation = () => {
    setIsModalOpen(false);
    history.block(() => {});

    if (nextPath) {
      history.push(nextPath);
    }
  };

  const handleCancelNavigation = () => {
    setIsModalOpen(false);
    setNextPath(null);
  };

  return (
    <>
      <Modal
        title='Deseja abandonar o cadastro?'
        open={isModalOpen}
        onOk={handleCancelNavigation}
        okText='Continuar cadastrando'
        cancelText='Sair da página'
        onCancel={handleConfirmNavigation}
        closable={false}
      >
        <p>
          Ao sair da página de cadastro, você perderá as informações preenchidas até agora e não será possível
          recuperá-la.
        </p>
      </Modal>

      <Grid container spacing={4} style={{ marginBottom: 40 }}>
        <Grid item xs={12}>
          <S.Title>Criar evento</S.Title>
          <S.Subtitle>Você está um passo mais próximo de tornar seu evento uma realidade!</S.Subtitle>
        </Grid>
        <Grid item xs={12}>
          <StepButtonsGroup />
        </Grid>
        <Grid item xs={12}>
          <Form isCreate />
        </Grid>
      </Grid>
    </>
  );
};

const CreateEvent = () => {
  return (
    <FormContextProvider isCreate>
      <CreateEventContent />
    </FormContextProvider>
  );
};

export default CreateEvent;
